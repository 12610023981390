import React from 'react';
import './style.css';

function App() {
  return (
    <main>
      <h1>Welcome to lifeautomation.com.au</h1>
      <p>This domain name is already owned</p>
    </main>
  );
}

export default App;
